"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
class Footer extends React.Component {
    render() {
        return (React.createElement("footer", null,
            React.createElement("br", null)));
    }
}
exports.Footer = Footer;
