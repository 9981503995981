"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const ReactDom = require("react-dom");
const Root_1 = require("./Root");
const Store_1 = require("./Store");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
require("../styles/index.scss");
let renderFunction = DEVELOPMENT ? ReactDom.render : ReactDom.hydrate;
setTimeout(() => {
    renderFunction(React.createElement(react_redux_1.Provider, { store: Store_1.AppStore },
        React.createElement(react_router_dom_1.BrowserRouter, null,
            React.createElement(Root_1.default, null))), document.getElementById("appContainer"));
}, 50);
