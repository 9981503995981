"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
;
;
;
class Impressum extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    componentDidUpdate() {
    }
    componentDidMount() {
    }
    ;
    render() {
        return (React.createElement("div", { className: "impressum" },
            React.createElement("h3", { className: 'impressum-title' }, "IMPRESSUM"),
            React.createElement("div", { className: 'impressum-row' },
                React.createElement("div", { className: 'impressum-left' },
                    React.createElement("p", null,
                        "\u00A9 2018",
                        React.createElement("br", null),
                        "dctp",
                        React.createElement("br", null),
                        "Entwicklungsgesellschaft",
                        React.createElement("br", null),
                        "f\u00FCr TV-Programm mbH",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Postanschrift",
                        React.createElement("br", null),
                        "Steinstra\u00DFe 4",
                        React.createElement("br", null),
                        "40212 D\u00FCsseldorf",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("span", { className: "grey" }, "Telefon"),
                        " +49 (0) 211 139 92 23",
                        React.createElement("br", null),
                        React.createElement("span", { className: "grey" }, "Fax"),
                        " +49 (0) 211 139 92 27",
                        React.createElement("br", null),
                        React.createElement("span", { className: "grey" }, "EMail"),
                        " ",
                        React.createElement("a", { href: "mailto:info@dctp.de" }, "info@dctp.de"),
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("span", { className: "grey" }, "Vertretungsberechtigte Gesch\u00E4ftsf\u00FChrer"),
                        React.createElement("br", null),
                        "Alexander Kluge, Fumio Oshima, Jakob Krebs",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("span", { className: "grey" },
                            "Umsatzsteuer-Identifikationsnummer",
                            React.createElement("br", null),
                            "gem\u00E4\u00DF \u00A7 27 a Umsatzsteuergesetz"),
                        React.createElement("br", null),
                        "DE 121293495",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Amtsgericht D\u00FCsseldorf",
                        React.createElement("br", null),
                        "Registernummer HRB 21 668",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("span", { className: "grey" },
                            "Inhaltlich Verantwortlicher",
                            React.createElement("br", null),
                            "\u00A7 55 Abs. 2 RStV"),
                        React.createElement("br", null),
                        "Alexander Kluge (Adresse s.o.)",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("span", { className: "grey" }, "Redaktion"),
                        React.createElement("br", null),
                        "Jakob Krebs (Ltg.), Marita H\u00FCttepohl,",
                        React.createElement("br", null),
                        "Beata Wiggen, Barbara Barnak",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("span", { className: "grey" }, "Jugendschutzbeauftragter"),
                        React.createElement("br", null),
                        "Paul Leo Giani (ra.paul.leo.giani@t-online.de)")),
                React.createElement("div", { className: 'impressum-right' },
                    React.createElement("p", null,
                        React.createElement("span", { className: "grey" }, "Konzeption"),
                        React.createElement("br", null),
                        "Jakob Krebs, Nikolai Longolius",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("span", { className: "grey" }, "Realisation"),
                        React.createElement("br", null),
                        "schnee von morgen webTV GmbH",
                        React.createElement("br", null),
                        React.createElement("a", { href: "http://www.schneevonmorgen.com/", target: "_blank" }, "www.schneevonmorgen.com"),
                        React.createElement("br", null),
                        "(Nikolai Longolius, Vincent Schreiter, Fabian Topfstedt)",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("span", { className: "grey" }, "Haftungshinweis"),
                        React.createElement("br", null),
                        "Trotz sorgf\u00E4ltiger inhaltlicher Kontrolle \u00FCbernehmen wir keine Haftung f\u00FCr die Inhalte externer",
                        React.createElement("br", null),
                        "Links. F\u00FCr den Inhalt der verlinkten Seiten sind ausschlie\u00DFlich deren Betreiber verantwortlich.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("span", { className: "grey" }, "Hinweis"),
                        React.createElement("br", null),
                        "Der Inhalt dieser Website / App ist urheberrechtlich gesch\u00FCtzt. dctp gew\u00E4hrt Ihnen jedoch das Recht, den auf dieser Website / App bereitgestellten Text, ganz oder ausschnittweise nur f\u00FCr private Zwecke zu speichern und zu vervielf\u00E4ltigen. Die \u00DCbernahme und Nutzung der Daten zu anderen Zwecken bedarf der schriftlichen Zustimmung seitens der dctp.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("span", { className: "grey" }, "Zust\u00E4ndige Aufsichtsbeh\u00F6rde:"),
                        React.createElement("br", null),
                        "Landesanstalt f\u00FCr Medien Nordrhein-Westfalen (LfM)",
                        React.createElement("br", null),
                        "Postfach 10 34 43",
                        React.createElement("br", null),
                        "40025 D\u00FCsseldorf",
                        React.createElement("br", null),
                        "Tel.: +49 (0)211 77 00 7 -0",
                        React.createElement("br", null),
                        "Fax: +49 (0)211 72 71 70",
                        React.createElement("br", null),
                        "E-Mail: ",
                        React.createElement("a", { href: "mailto:info@lfm-nrw.de" }, "info@lfm-nrw.de"),
                        React.createElement("br", null),
                        "Internet: ",
                        React.createElement("a", { title: "Landesanstalt f\u00FCr Medien Nordrhein-Westfalen", href: "http://www.lfm-nrw.de", target: "_blank" }, "http://www.lfm-nr.de")))),
            React.createElement("div", { className: 'impressum-bottom' },
                React.createElement("p", null,
                    React.createElement("span", { className: "grey" }, "Datenschutzbestimmungen"),
                    React.createElement("br", null),
                    "Diese Website / App benutzt Google Analytics, einen Webanalysedienst der Google Inc. (\u201EGoogle\u201C) Google Analytics verwendet sog. \u201ECookies\u201C, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website / App durch Sie erm\u00F6glicht. Die durch den Cookie erzeugten Informationen \u00FCber Ihre Benutzung diese Website / App (einschlie\u00DFlich Ihrer IP-Adresse) wird an einen Server von Google in den USA \u00FCbertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website / App auszuwerten, um Reports \u00FCber die Website / Appaktivit\u00E4ten f\u00FCr die Website / Appbetreiber zusammenzustellen und um weitere mit der Website / Appnutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte \u00FCbertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung bringen. Sie k\u00F6nnen die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht s\u00E4mtliche Funktionen dieser Website / App voll umf\u00E4nglich nutzen k\u00F6nnen. Durch die Nutzung dieser Website / App erkl\u00E4ren Sie sich mit der Bearbeitung der \u00FCber Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden. Datenschutzbeauftragter: Paul Leo Giani (",
                    React.createElement("span", { className: "grey" }, "ra.paul.leo.giani@t-online.de"),
                    "). Weitere Informationen zum Datenschutz finden Sie unter: ",
                    React.createElement("span", { className: "grey" },
                        React.createElement("a", { href: "http://magazin.dctp.tv/impressum/", target: "_blank" }, "http://magazin.dctp.tv/impressum/"))))));
    }
    ;
}
;
const mapStateToProps = (state, ownProp) => {
    return {};
};
const mapDispatchToProps = (dispatch) => ({});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Impressum);
