"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_slick_1 = require("react-slick");
const react_redux_1 = require("react-redux");
const ivms = require("../ivms/ivms");
const helper_functions_1 = require("../utils/helper-functions");
const react_debounce_input_1 = require("react-debounce-input");
const lodash_1 = require("lodash");
const KeyboardArrowLeft_1 = require("@material-ui/icons/KeyboardArrowLeft");
const KeyboardArrowRight_1 = require("@material-ui/icons/KeyboardArrowRight");
const react_device_detect_1 = require("react-device-detect");
const isNode = require("detect-node");
const dctp_logo = require("../../images/dctp-tv.jpg");
const search_icon = require("../../images/icon-search.png");
class Header extends React.Component {
    constructor() {
        super(...arguments);
        this.results = [];
        this.state = {
            menuOpen: false,
            searchString: ''
        };
        this.searchInput = null;
        this.handleSearch = (e) => __awaiter(this, void 0, void 0, function* () {
            let value = e.target.value;
            this.setState(lodash_1.merge(this.state, {
                searchString: value || '',
                menuOpen: !lodash_1.isEmpty(value),
            }));
            if (!value.length) {
                return;
            }
            let path = "https://search.schneevonmorgen.com/dctptv/_search?preference=__pref__&q=(__query__%20OR%20__query__*)%20AND%20restapi_version:__restapi__&from=__from__&size=__size__";
            path = path.replace(/__pref__/g, helper_functions_1.randomString(20));
            path = path.replace(/__restapi__/g, this.props.version_name);
            path = path.replace(/__from__/g, '0');
            path = path.replace(/__size__/g, '20');
            path = path.replace(/__query__/g, this.searchEncode(e.target.value));
            this.fetch_results = yield (yield fetch(path)).json().then((data) => {
                this.results = data.hits.hits;
                this.setState(lodash_1.merge(this.state, { menuOpen: !lodash_1.isEmpty(value) && !lodash_1.isEmpty(data.hits.hits) }));
            }).catch(() => {
            });
        });
    }
    searchEncode(value) {
        for (let entry of this.encodingList()) {
            value = value.replace(entry[0], entry[1]);
        }
        return encodeURIComponent(value.trim());
    }
    encodingList() {
        var encodingList = [];
        encodingList.push([/\+/g, '\\+']);
        encodingList.push([/\-/g, '\\-']);
        encodingList.push([/\*/g, '\\*']);
        encodingList.push([/\//g, '\\/']);
        encodingList.push([/\:/g, '\\:']);
        encodingList.push([/\!/g, '\\!']);
        encodingList.push([/\?/g, '\\?']);
        encodingList.push([/\^/g, '\\^']);
        encodingList.push([/\"/g, '\\"']);
        encodingList.push([/\~/g, '\\~']);
        encodingList.push([/\(/g, '\\(']);
        encodingList.push([/\)/g, '\\)']);
        encodingList.push([/\{/g, '\\{']);
        encodingList.push([/\}/g, '\\}']);
        encodingList.push([/\[/g, '\\[']);
        encodingList.push([/\]/g, '\\]']);
        encodingList.push([/\&\&/g, ' ']);
        encodingList.push([/\|\|/g, ' ']);
        encodingList.push([/ AND$/g, ' ']);
        encodingList.push([/^AND /g, ' ']);
        encodingList.push([/ OR$/g, ' ']);
        encodingList.push([/^OR /g, ' ']);
        return encodingList;
    }
    ;
    componentDidMount() {
        const { getContainerData, restapi_url, items } = this.props;
        if (restapi_url && lodash_1.isEmpty(items))
            getContainerData(10, restapi_url);
    }
    render() {
        const { items } = this.props;
        if (isNode) {
            this.componentDidMount();
        }
        const settings = {
            arrows: false,
            dots: false,
            infinite: true,
            centerMode: true,
            slidesToShow: 1,
            variableWidth: true,
            adaptiveHeight: true
        };
        var hasResult = this.results.length > 0 ? true : null;
        let searchStyle = {
            display: this.state.menuOpen ? "block" : "none"
        };
        return (React.createElement("header", null,
            React.createElement("div", { className: "header" },
                React.createElement("a", { href: "/", className: "logo_container", style: { backgroundImage: `url(${dctp_logo})` } }),
                React.createElement("ul", { className: "header-inline-items" }, !lodash_1.isEmpty(items) && items.map((item, index) => (React.createElement("li", { key: index },
                    React.createElement("a", { href: item.url, className: "nav-item" }, item.unicode))))),
                React.createElement("div", { className: "search" },
                    React.createElement(react_debounce_input_1.DebounceInput, { placeholder: "SUCHE", minLength: 2, inputRef: ref => { this.searchInput = ref; }, debounceTimeout: 300, onChange: this.handleSearch }),
                    React.createElement("img", { src: "/images/icon-search.png", style: { cursor: "pointer" }, onClick: (event) => { this.searchInput.focus(); } }))),
            React.createElement("div", { className: "searchResults", style: searchStyle },
                React.createElement("div", { className: "media-swiper" },
                    React.createElement("div", { className: "swiper-container" },
                        React.createElement(KeyboardArrowLeft_1.default, { style: { fill: "#ffffff" }, className: "arrow-left", onClick: () => this.refs.slider.slickPrev() }),
                        React.createElement(KeyboardArrowRight_1.default, { style: { fill: "#ffffff" }, className: "arrow-right", onClick: () => this.refs.slider.slickNext() }),
                        React.createElement(react_slick_1.default, Object.assign({ ref: "slider" }, settings), this.results && hasResult && this.results.map((media_item, index) => {
                            return (React.createElement("div", { key: index },
                                React.createElement("a", { href: '/filme/' + ((media_item && media_item._source.slug) || ''), key: 'link-' + index, className: 'swiper-item' },
                                    React.createElement("div", { className: 'swiper-item-content' },
                                        React.createElement("div", { className: "swiper-item-padding" },
                                            media_item &&
                                                React.createElement("div", { className: 'swiper-item-image', style: {
                                                        backgroundImage: `url(${media_item._source.image})`
                                                    }, key: index + '-image' },
                                                    React.createElement("div", { className: (react_device_detect_1.isMobile) ? "hover-play touch" : "hover-play" },
                                                        React.createElement("img", { src: "/images/icon-play.png", width: "70", height: "70" }))),
                                            media_item &&
                                                React.createElement("h3", { className: 'swiper-item-title', key: index + '-link' }, media_item._source.title))))));
                        })))))));
    }
}
const mapStateToProps = (state, ownProp) => {
    return lodash_1.merge({
        restapi_url: state.ivms.restapi_url,
        version_name: state.ivms.config.version_name,
    }, lodash_1.find(state.ivms.containers, (c) => { return c.id === 10; }));
};
const mapDispatchToProps = (dispatch) => ({
    getContainerData: (containerId, restapi_url) => dispatch(ivms.getContainerData(containerId, restapi_url)),
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Header);
