"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
const ivms = require("../ivms/ivms");
const lodash_1 = require("lodash");
const helper_functions_1 = require("../utils/helper-functions");
const isNode = require("detect-node");
const react_device_detect_1 = require("react-device-detect");
;
;
;
class Thema extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            playlist_items_ensured: false,
            media_items_ensured: false,
        };
    }
    ensureMediaItems() {
        const { media_items, playlist_items, getMediaFromId, slug, restapi_url } = this.props;
        const playlist_item = lodash_1.find(playlist_items, p => p.slug === slug);
        if (playlist_item) {
            let containsMedia = playlist_item.contains_media;
            containsMedia.forEach(item => {
                let id = item.pk;
                let res = lodash_1.find(media_items, mi => mi.id === id);
                if (!res) {
                    getMediaFromId(id, restapi_url);
                }
            });
            this.setState(lodash_1.merge(this.state, { media_items_ensured: true }));
        }
    }
    ;
    ensurePlaylist(restapi_url) {
        const { playlist_items, slug, getPlaylistFromId } = this.props;
        if (restapi_url) {
            const item = lodash_1.find(playlist_items, p => p.slug === slug);
            if (lodash_1.isEmpty(item) || typeof item === 'undefined') {
                ivms.getSlug(slug, restapi_url)
                    .then(pl => getPlaylistFromId(pl.object_id, restapi_url));
                this.setState(lodash_1.merge(this.state, { playlist_items_ensured: true }));
            }
        }
    }
    ;
    componentWillReceiveProps({ restapi_url }) {
        const { playlist_items_ensured } = this.state;
        if (this.props.restapi_url != restapi_url && !playlist_items_ensured) {
            this.ensurePlaylist(restapi_url);
        }
        ;
    }
    ;
    componentDidUpdate() {
        const { restapi_url } = this.props;
        const { playlist_items_ensured, media_items_ensured } = this.state;
        if (restapi_url && playlist_items_ensured && !media_items_ensured) {
            this.ensureMediaItems();
        }
    }
    componentDidMount() {
        if (this.props.slug !== 'undefined') {
            this.ensurePlaylist(this.props.restapi_url);
            this.ensureMediaItems();
        }
    }
    ;
    render() {
        if (isNode)
            this.componentDidMount();
        const { slug, playlist_items, media_items } = this.props;
        const item = lodash_1.find(playlist_items, p => p.slug === slug);
        if (lodash_1.isEmpty(item) || lodash_1.isEmpty(item.media) || slug === 'undefined') {
            return (React.createElement("div", { style: { height: "1200px" } }));
        }
        else {
            return (React.createElement("div", { className: "thema-container" },
                React.createElement("div", { className: "image" },
                    React.createElement("div", { className: "poster", style: { backgroundImage: `url(${helper_functions_1.getImageUrlBySlug(item, "2018-standard")})` } })),
                React.createElement("h3", { className: 'thema-title' }, item.title),
                React.createElement("div", { className: "thema-subtitle" },
                    React.createElement("i", null, item.subtitle)),
                React.createElement("div", { className: "thema-description" },
                    React.createElement("p", null, item.description)),
                React.createElement("div", { className: "thema-media-grid" }, item.media.map((id, index) => {
                    let media_item = lodash_1.find(media_items, m => m.id === id);
                    return (React.createElement("div", { key: index, className: "thema-item" },
                        React.createElement("a", { href: '/filme/' + (media_item && media_item.slug) + this.props.query_uri, key: index + '-inner', className: "thema-item-inner" },
                            React.createElement("div", { className: "thema-item-padding" },
                                React.createElement("div", { className: "thema-item-image", style: { backgroundImage: `url(${(media_item && helper_functions_1.getImageUrlBySlug(media_item, "medium-preview-16-9"))})` }, key: index + '-image' },
                                    React.createElement("div", { className: (react_device_detect_1.isMobile) ? "hover-play touch" : "hover-play" },
                                        React.createElement("img", { src: "/images/icon-play.png" }))),
                                React.createElement("h3", { className: 'thema-item-title', key: index + '-link' }, media_item && media_item.title),
                                React.createElement("p", { key: index + '-desc', className: "thema-item-teaser" }, media_item && media_item.teaser)))));
                }))));
        }
        ;
    }
    ;
}
;
const mapStateToProps = (state, ownProp) => {
    return {
        restapi_url: state.ivms.restapi_url,
        query_uri: state.query_uri,
        playlist_items: state.ivms.playlist_items,
        media_items: state.ivms.media_items,
        slug: ownProp.slug,
    };
};
const mapDispatchToProps = (dispatch) => ({
    getPlaylistFromId: (id, restapi_url) => dispatch(ivms.getPlaylistFromId(id, restapi_url)),
    getMediaFromId: (id, restapi_url) => dispatch(ivms.getMediaFromId(id, restapi_url)),
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Thema);
