"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const ivms_redux_actions_1 = require("./ivms-redux-actions");
const Store_1 = require("../Store");
const isNode = require("detect-node");
require('es6-promise').polyfill();
require('fetch-everywhere');
const BASE_STREAM_URL = DEVELOPMENT ?
    'https://completed-media.s3.amazonaws.com' :
    'https://cdn-segments.dctp.tv';
const STREAM_URL_SUFFIX = DEVELOPMENT ? '' : '/playlist.m3u8';
const STREAM_MEDIA_TYPE = DEVELOPMENT ? 'video/mp4' : 'application/x-mpegURL';
function getStreamUrl(uuid, quality, is_wide = true) {
    let url = `${BASE_STREAM_URL}/${uuid}_dctp_${(is_wide) ? quality : "0500_4x3"}.m4v${STREAM_URL_SUFFIX}`;
    return { src: url, type: STREAM_MEDIA_TYPE };
}
exports.getStreamUrl = getStreamUrl;
exports.getSlug = (slugName, restapi_url) => __awaiter(this, void 0, void 0, function* () {
    const slugSpecUrl = `${restapi_url}slugs/${slugName}.json`;
    const jsonResponse = yield (yield fetch(slugSpecUrl)).json();
    return jsonResponse;
});
exports.getContainerData = (containerId, restapi_url) => {
    let defaultFetch = () => {
        return (dispatch) => __awaiter(this, void 0, void 0, function* () {
            const containerUrl = `${restapi_url}containers/${containerId}.json`;
            const jsonResponse = yield (yield fetch(containerUrl)).json();
            dispatch(ivms_redux_actions_1.getContainerDataById(jsonResponse));
        });
    };
    if (isNode) {
        let storeState = Store_1.AppStore.getState();
        let containers = storeState.ivms.containers;
        const maybe_cached_data = lodash_1.find(storeState.ivms.containers, (c) => { return c.id === containerId; });
        if (!lodash_1.isEmpty(maybe_cached_data)) {
            return ivms_redux_actions_1.getContainerDataById(maybe_cached_data);
        }
        else {
            return defaultFetch();
        }
    }
    else {
        return defaultFetch();
    }
};
exports.getPlaylistFromId = (id, restapi_url) => {
    var maybe_cached_data;
    if (isNode) {
        let storeState = Store_1.AppStore.getState();
        maybe_cached_data = lodash_1.find(storeState.ivms.playlist_items, (i) => {
            return i.id === id;
        });
    }
    if (isNode && !lodash_1.isEmpty(maybe_cached_data)) {
        return ivms_redux_actions_1.getPlaylistSpecById(maybe_cached_data);
    }
    else {
        return (dispatch) => __awaiter(this, void 0, void 0, function* () {
            try {
                const playlistSpecUrl = `${restapi_url}playlists/${id}.json`;
                const jsonResponse = yield (yield fetch(playlistSpecUrl)).json();
                dispatch(ivms_redux_actions_1.getPlaylistSpecById(jsonResponse));
            }
            catch (error) {
                console.error(error);
            }
        });
    }
};
exports.getPlaylistSetFromId = (id, restapi_url) => {
    var maybe_cached_data;
    if (isNode) {
        let storeState = Store_1.AppStore.getState();
        maybe_cached_data = lodash_1.find(storeState.ivms.playlistset_items, (i) => {
            return i.id === id;
        });
    }
    if (isNode && !lodash_1.isEmpty(maybe_cached_data)) {
        return ivms_redux_actions_1.getPlaylistSetSpecById(maybe_cached_data);
    }
    else {
        return (dispatch) => __awaiter(this, void 0, void 0, function* () {
            try {
                const playlistSetSpecUrl = `${restapi_url}playlistsets/${id}.json`;
                const jsonResponse = yield (yield fetch(playlistSetSpecUrl)).json();
                dispatch(ivms_redux_actions_1.getPlaylistSetSpecById(jsonResponse));
            }
            catch (error) {
                console.error(error);
            }
        });
    }
};
exports.getMediaFromId = (id, restapi_url) => {
    var maybe_cached_data;
    if (isNode) {
        let storeState = Store_1.AppStore.getState();
        maybe_cached_data = lodash_1.find(storeState.ivms.media_items, (i) => { return i.id === id; });
    }
    if (isNode && !lodash_1.isEmpty(maybe_cached_data)) {
        return ivms_redux_actions_1.getMediaSpecById(maybe_cached_data);
    }
    return (dispatch) => __awaiter(this, void 0, void 0, function* () {
        try {
            const mediaSpecUrl = `${restapi_url}media/${id}.json`;
            const jsonResponse = yield (yield fetch(mediaSpecUrl)).json();
            dispatch(ivms_redux_actions_1.getMediaSpecById(jsonResponse));
        }
        catch (error) {
            console.error(error);
        }
    });
};
exports.getMediaFromSlug = (slugName, restapi_url, error_cb) => {
    var maybe_cached_data;
    if (isNode) {
        let storeState = Store_1.AppStore.getState();
        maybe_cached_data = lodash_1.find(storeState.ivms.media_items, (i) => {
            return i.slug === slugName;
        });
    }
    if (isNode && !lodash_1.isEmpty(maybe_cached_data)) {
        return ivms_redux_actions_1.getMediaSpecById(maybe_cached_data);
    }
    else {
        return (dispatch) => __awaiter(this, void 0, void 0, function* () {
            let slug = yield exports.getSlug(slugName, restapi_url);
            if (!slug)
                return error_cb();
            const mediaSpecUrl = `${restapi_url}media/${slug.object_id}.json`;
            const jsonResponse = yield (yield fetch(mediaSpecUrl)).json();
            if (!jsonResponse)
                return error_cb();
            dispatch(ivms_redux_actions_1.getMediaSpecById(jsonResponse));
        });
    }
};
exports.getAllPlaylistsIds = (restapi_url) => {
    var maybe_cached_data;
    if (isNode) {
        let storeState = Store_1.AppStore.getState();
        maybe_cached_data = storeState.ivms.all_playlists_ids;
    }
    if (isNode && !lodash_1.isEmpty(maybe_cached_data)) {
        return ivms_redux_actions_1.getAllPlaylistsIdsAction(maybe_cached_data);
    }
    else {
        return (dispatch) => __awaiter(this, void 0, void 0, function* () {
            try {
                const playlistsUrl = `${restapi_url}playlists.json`;
                const jsonResponse = yield (yield fetch(playlistsUrl)).json();
                dispatch(ivms_redux_actions_1.getAllPlaylistsIdsAction(jsonResponse));
            }
            catch (error) {
                console.error(error);
            }
        });
    }
};
exports.getAllPlaylistSetIds = (restapi_url) => {
    var maybe_cached_data;
    if (isNode) {
        let storeState = Store_1.AppStore.getState();
        maybe_cached_data = storeState.ivms.all_playlistset_ids;
    }
    if (isNode && !lodash_1.isEmpty(maybe_cached_data)) {
        return ivms_redux_actions_1.getAllPlaylistSetIdsAction(maybe_cached_data);
    }
    else {
        return (dispatch) => __awaiter(this, void 0, void 0, function* () {
            try {
                const playlistSetsUrl = `${restapi_url}playlistsets.json`;
                console.log(playlistSetsUrl);
                const jsonResponse = yield (yield fetch(playlistSetsUrl)).json();
                dispatch(ivms_redux_actions_1.getAllPlaylistSetIdsAction(jsonResponse));
            }
            catch (error) {
                console.error(error);
            }
        });
    }
};
exports.getAllMediaIds = (restapi_url) => {
    var maybe_cached_data;
    if (isNode) {
        let storeState = Store_1.AppStore.getState();
        maybe_cached_data = storeState.ivms.all_media_ids;
    }
    if (isNode && !lodash_1.isEmpty(maybe_cached_data)) {
        return ivms_redux_actions_1.getAllMediaIdsAction(maybe_cached_data);
    }
    else {
        return (dispatch) => __awaiter(this, void 0, void 0, function* () {
            try {
                const allMediaUrl = `${restapi_url}media.json`;
                const jsonResponse = yield (yield fetch(allMediaUrl)).json();
                dispatch(ivms_redux_actions_1.getAllMediaIdsAction(jsonResponse));
            }
            catch (error) {
                console.error(error);
            }
        });
    }
};
