"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
const ivms = require("../ivms/ivms");
const lodash_1 = require("lodash");
const isNode = require("detect-node");
const helper_functions_1 = require("../utils/helper-functions");
const SectionTitle_1 = require("../components/SectionTitle");
const MediaSwiper2_1 = require("../components/MediaSwiper2");
;
;
class BigMediaSwiperWithCover extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { restapi_url, id, items, getPlaylistFromId, playlist_items } = this.props;
        if (items && items[0] && (!prevProps.items || (prevProps.items.length < 1))) {
            let first_item = items[0];
            let playlist_item = lodash_1.find(playlist_items, (pl) => { return pl.id === first_item.id; });
            if (!playlist_item && first_item.module === 'playlist') {
                getPlaylistFromId(first_item.id, restapi_url);
            }
        }
    }
    componentDidMount() {
        const { restapi_url, id, items, getContainerData, getPlaylistFromId } = this.props;
        if (restapi_url && lodash_1.isEmpty(items)) {
            getContainerData(id, restapi_url);
        }
        else if (restapi_url) {
            let first_item = items[0];
            if (first_item.module === 'playlist') {
                getPlaylistFromId(first_item.id, restapi_url);
            }
        }
    }
    render() {
        if (isNode) {
            this.componentDidMount();
        }
        const { playlist_items, items, title } = this.props;
        let first_item = items && items[0];
        let playlist_item = first_item && lodash_1.find(playlist_items, (pl) => { return pl.id === first_item.id; });
        if (!playlist_item)
            return (React.createElement("div", null));
        return (React.createElement("div", { className: "big-media-swiper-with-cover-container" },
            React.createElement(SectionTitle_1.default, { title: title }),
            React.createElement("a", { href: '/themen/' + playlist_item.slug + this.props.query_uri },
                React.createElement("div", { className: "big-media-swiper-with-cover-cover", style: { backgroundImage: `url(${helper_functions_1.getImageUrlBySlug(playlist_item, "2018-flat")})` } })),
            React.createElement(MediaSwiper2_1.default, { playlistID: first_item.id, bigItems: true })));
    }
}
exports.BigMediaSwiperWithCover = BigMediaSwiperWithCover;
const mapStateToProps = (state, ownProp) => {
    return lodash_1.merge({
        id: ownProp.id,
        restapi_url: state.ivms.restapi_url,
        query_uri: state.query_uri,
        playlist_items: state.ivms.playlist_items,
    }, lodash_1.find(state.ivms.containers, (c) => { return c.id === ownProp.id; }));
};
const mapDispatchToProps = (dispatch) => ({
    getContainerData: (containerId, restapi_url) => dispatch(ivms.getContainerData(containerId, restapi_url)),
    getPlaylistFromId: (id, restapi_url) => dispatch(ivms.getPlaylistFromId(id, restapi_url)),
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(BigMediaSwiperWithCover);
