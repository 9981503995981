"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const SectionTitle = (props) => {
    const { title } = props;
    return (React.createElement("div", { className: "section-title" },
        React.createElement("div", { className: "section-title-bullet" }),
        React.createElement("h1", null, title)));
};
exports.default = SectionTitle;
