"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_visibility_sensor_1 = require("@tgrx/react-visibility-sensor");
const react_redux_1 = require("react-redux");
const react_router_1 = require("react-router");
const ivms = require("../ivms/ivms");
const lodash_1 = require("lodash");
const SectionTitle_1 = require("../components/SectionTitle");
const isNode = require("detect-node");
const Thema_1 = require("./Thema");
const helper_functions_1 = require("../utils/helper-functions");
const isPrerendered = isNode ? null : window.__ivms_ssr__;
;
;
;
class Themen extends React.Component {
    constructor() {
        super(...arguments);
        this.titles = [];
        this.state = {
            rendered_playlist_items: [],
            inview_playlist_ids: [],
            page_number: 0,
            isLoading: false,
            hasMore: true,
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (isNode && this.props.match.params.id !== 'undefined'
            && nextProps.match.params.id === 'undefined')
            return false;
        return true;
    }
    maybeAppendPlaylistItems() {
        const { rendered_playlist_items, inview_playlist_ids, page_number } = this.state;
        const { playlist_items } = this.props;
        if (rendered_playlist_items.length != inview_playlist_ids.length) {
            let missingIds = lodash_1.dropWhile(inview_playlist_ids, (id => {
                return !!lodash_1.find(playlist_items, p => p.id === id);
            }));
            if (lodash_1.isEmpty(missingIds)) {
                var new_playlist_items = [];
                var bgcolor = "#333333";
                inview_playlist_ids.map((id, index) => {
                    if (index > rendered_playlist_items.length) {
                        var item = lodash_1.find(playlist_items, (p) => p.id === id);
                        new_playlist_items.push(React.createElement("div", { key: index, className: "themen-item" },
                            React.createElement("div", { style: { backgroundColor: `${bgcolor}` }, ref: (title) => { this.titles[index] = title; }, key: index + "-title" },
                                React.createElement("div", { className: "title" }, item.title)),
                            React.createElement("div", { className: "image-container", key: index + "-image" },
                                React.createElement("div", { className: "komplex-image" },
                                    React.createElement("img", { src: helper_functions_1.getImageUrlBySlug(item, "2018-standard") }))),
                            React.createElement("a", { href: '/themen/' + item.slug + this.props.query_uri, key: index + "-anchor" })));
                    }
                    ;
                });
                this.setState(lodash_1.merge(this.state, {
                    rendered_playlist_items: lodash_1.concat(this.state.rendered_playlist_items, new_playlist_items),
                    isLoading: false,
                }));
            }
            ;
        }
        ;
    }
    ;
    getNextPlaylistItems(isVisible) {
        if (isVisible && !lodash_1.isEmpty(this.props.all_playlists_ids) && this.state.isLoading == false) {
            let newPageNumber = isNode ? 0 : this.state.page_number + 1;
            const { playlist_items, restapi_url, all_playlists_ids, getPlaylistFromId } = this.props;
            let slicedPlaylistIds = lodash_1.slice(all_playlists_ids, newPageNumber * 24, (newPageNumber * 24) + 24);
            if (slicedPlaylistIds.length < 24) {
                setTimeout(() => this.setState(lodash_1.merge(this.state, { hasMore: false })), 1000);
            }
            var shouldLoad = false;
            slicedPlaylistIds.forEach(id => {
                let item = lodash_1.find(playlist_items, p => p.id === id);
                if (lodash_1.isEmpty(item)) {
                    getPlaylistFromId(id, restapi_url);
                    if (!shouldLoad) {
                        shouldLoad = true;
                    }
                    ;
                }
                ;
            });
            this.setState(lodash_1.merge(this.state, {
                inview_playlist_ids: lodash_1.concat(this.state.inview_playlist_ids, slicedPlaylistIds),
                page_number: newPageNumber,
                isLoading: shouldLoad,
            }));
            if (!shouldLoad) {
                this.maybeAppendPlaylistItems();
            }
        }
        ;
    }
    ;
    UNSAFE_componentWillReceiveProps({ playlist_items, all_playlists_ids, match }) {
        let overviewView = typeof match.params.id === 'undefined' ? true : false;
        if (overviewView &&
            (all_playlists_ids.length != this.props.all_playlists_ids.length) &&
            (this.state.rendered_playlist_items.length === 0)) {
            if (isPrerendered) {
                this.setState(lodash_1.merge(this.state, {
                    inview_playlist_ids: lodash_1.slice(all_playlists_ids, 0, 24),
                }));
            }
            else {
                setTimeout(() => this.getNextPlaylistItems(true), 0);
            }
        }
        setTimeout(() => this.maybeAppendPlaylistItems(), 0);
    }
    ;
    componentWillUpdate({ restapi_url, all_playlists_ids, match }) {
        let overviewView = typeof match.params.id === 'undefined' ? true : false;
        if (overviewView && restapi_url && lodash_1.isEmpty(all_playlists_ids)) {
            this.props.getAllPlaylistsIds(restapi_url);
        }
        if (overviewView &&
            (all_playlists_ids.length != this.props.all_playlists_ids.length) &&
            (this.state.rendered_playlist_items.length === 0)) {
            this.getNextPlaylistItems(true);
        }
        if (overviewView && (this.state.rendered_playlist_items.length === 0)) {
            this.maybeAppendPlaylistItems();
        }
    }
    componentDidUpdate() {
        this.checkTitles();
    }
    checkTitles() {
        for (let i = 0; i < this.titles.length; i++) {
            let t = this.titles[i];
            let base = 30;
            if (t) {
                while (t.offsetHeight != t.scrollHeight) {
                    base -= 1;
                    t.firstChild.style.fontSize = base + "px";
                }
            }
        }
    }
    ;
    componentDidMount() {
        const { restapi_url, all_playlists_ids, match } = this.props;
        let overviewView = typeof match.params.id === 'undefined' ? true : false;
        if (overviewView && restapi_url && lodash_1.isEmpty(all_playlists_ids)) {
            this.props.getAllPlaylistsIds(restapi_url);
        }
        if (overviewView && !isPrerendered && !lodash_1.isEmpty(all_playlists_ids)) {
            this.getNextPlaylistItems(true);
        }
        if (!overviewView && !isNode) {
            scrollTo(0, 0);
        }
    }
    render() {
        if (isNode)
            this.componentDidMount();
        const { all_playlists_ids, match } = this.props;
        const { rendered_playlist_items, inview_playlist_ids, page_number } = this.state;
        let overviewView = typeof match.params.id === 'undefined' ? true : false;
        if (overviewView) {
            return (React.createElement("div", { className: "themen", style: { paddingTop: "92px" } },
                React.createElement(SectionTitle_1.default, { title: "Themen" }),
                React.createElement("div", { className: "themen-parent" },
                    React.createElement("div", { className: "themen-container" }, this.state.rendered_playlist_items),
                    this.state.hasMore && React.createElement(react_visibility_sensor_1.default, { className: "themen-loader-container", onChange: this.getNextPlaylistItems.bind(this) },
                        React.createElement("div", { className: "spinner" },
                            React.createElement("div", { className: "rect1" }),
                            React.createElement("div", { className: "rect2" }),
                            React.createElement("div", { className: "rect3" }),
                            React.createElement("div", { className: "rect4" }),
                            React.createElement("div", { className: "rect5" }))))));
        }
        else {
            return (React.createElement(Thema_1.default, { slug: match.params.id }));
        }
    }
}
const mapStateToProps = (state, ownProp) => {
    return {
        restapi_url: state.ivms.restapi_url,
        query_uri: state.query_uri,
        playlist_items: state.ivms.playlist_items,
        all_playlists_ids: state.ivms.all_playlists_ids,
    };
};
const mapDispatchToProps = (dispatch) => ({
    getAllPlaylistsIds: (restapi_url) => dispatch(ivms.getAllPlaylistsIds(restapi_url)),
    getPlaylistFromId: (id, restapi_url) => dispatch(ivms.getPlaylistFromId(id, restapi_url)),
});
exports.default = react_router_1.withRouter(react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Themen));
