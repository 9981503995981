"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_slick_1 = require("react-slick");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const memoizeOne = require("memoize-one/dist/memoize-one.cjs");
const KeyboardArrowLeft_1 = require("@material-ui/icons/KeyboardArrowLeft");
const KeyboardArrowRight_1 = require("@material-ui/icons/KeyboardArrowRight");
const ivms = require("../ivms/ivms");
const lodash_1 = require("lodash");
const helper_functions_1 = require("../utils/helper-functions");
const isNode = require("detect-node");
const SectionTitle_1 = require("./SectionTitle");
const react_device_detect_1 = require("react-device-detect");
const search_icon = require("../../images/icon-play.png");
;
;
;
const rotateOrder = memoizeOne((media_ids, currentFilmId) => {
    if (currentFilmId) {
        let indexOfFirstFilm = media_ids.indexOf(currentFilmId);
        if (indexOfFirstFilm > 0) {
            return lodash_1.concat(media_ids.slice(indexOfFirstFilm), media_ids.slice(0, indexOfFirstFilm));
        }
        else {
            return media_ids;
        }
    }
    else {
        return media_ids;
    }
});
class MediaSwiper extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            media_items_ensured: false
        };
    }
    ensureMediaItems(playlist_item) {
        const { playlist_items, media_items, restapi_url, playlistID, getMediaFromId } = this.props;
        let containsMedia = playlist_item.contains_media;
        containsMedia.forEach(item => {
            let id = item.pk;
            let res = lodash_1.find(media_items, mi => {
                return mi.id === id;
            });
            if (!res) {
                getMediaFromId(id, restapi_url);
            }
        });
        this.setState(lodash_1.merge(this.state, { media_items_ensured: true }));
    }
    componentDidUpdate() {
        const { playlist_items, playlistID } = this.props;
        let playlist_item = lodash_1.find(playlist_items, pi => {
            return pi.id === playlistID;
        });
        if (playlist_item && !this.state.media_items_ensured) {
            this.ensureMediaItems(playlist_item);
        }
        if (typeof this.refs.slider != 'undefined')
            this.refs.slider.slickGoTo(0, true);
    }
    ;
    componentDidMount() {
        const { playlist_items, restapi_url, playlistID, getPlaylistFromId } = this.props;
        let playlist_item = lodash_1.find(playlist_items, pi => {
            return pi.id === playlistID;
        });
        if (!playlist_item) {
            getPlaylistFromId(playlistID, restapi_url);
        }
        else {
            this.ensureMediaItems(playlist_item);
        }
    }
    UNSAFE_componentWillMount() {
        const { playlist_items, restapi_url, playlistID, getPlaylistFromId } = this.props;
        if (isNode) {
            this.componentDidMount();
        }
    }
    render() {
        const { playlist_items, playlistID, media_items, bigItems, restapi_url, currentFilmId, preventReload, showTitle } = this.props;
        var playlist_item = lodash_1.find(playlist_items, pi => pi.id === playlistID);
        var settings = {
            arrows: false,
            dots: false,
            infinite: true,
            centerMode: true,
            slidesToShow: 1,
            variableWidth: true,
            adaptiveHeight: true
        };
        if (playlist_item) {
            const containsMedia = playlist_item.contains_media;
            const playlistMedia = rotateOrder(playlist_item.media, currentFilmId);
            const LinkWrapper = (index, media_item, body) => {
                if (preventReload) {
                    return (React.createElement(react_router_dom_1.Link, { key: 'rlink-' + index, to: '/filme/' + ((media_item && media_item.slug) || '') + this.props.query_uri, className: 'swiper-item' }, body));
                }
                else {
                    return (React.createElement("a", { href: '/filme/' + ((media_item && media_item.slug) || '') + this.props.query_uri, key: 'link-' + index, className: 'swiper-item' }, body));
                }
            };
            return (React.createElement("div", { className: "media-swiper" },
                showTitle && React.createElement("a", { href: '/themen/' + playlist_item.slug },
                    React.createElement(SectionTitle_1.default, { title: playlist_item.title })),
                React.createElement("div", { className: "swiper-container" },
                    React.createElement(KeyboardArrowLeft_1.default, { style: { fill: "#ffffff" }, className: "arrow-left", onClick: () => this.refs.slider.slickPrev() }),
                    React.createElement(KeyboardArrowRight_1.default, { style: { fill: "#ffffff" }, className: "arrow-right", onClick: () => this.refs.slider.slickNext() }),
                    React.createElement(react_slick_1.default, Object.assign({ ref: "slider" }, settings), playlistMedia.map((id, index) => {
                        let media_item = lodash_1.find(media_items, mi => mi.id === id);
                        return (React.createElement("div", { key: index }, LinkWrapper(index, media_item, React.createElement("div", { className: 'swiper-item-content' },
                            React.createElement("div", { className: "swiper-item-padding" },
                                media_item &&
                                    React.createElement("div", { className: 'swiper-item-image', style: {
                                            backgroundImage: `url(${helper_functions_1.getImageUrlBySlug(media_item, "medium-preview-16-9")})`
                                        }, key: index + '-image' },
                                        React.createElement("div", { className: (react_device_detect_1.isMobile) ? "hover-play touch" : "hover-play" },
                                            React.createElement("img", { src: "/images/icon-play.png", width: "70", height: "70" }))),
                                media_item &&
                                    React.createElement("h3", { className: 'swiper-item-title', key: index + '-link' }, media_item.title),
                                media_item &&
                                    React.createElement("p", { className: 'swiper-item-description', key: index + '-desc' }, media_item.teaser))))));
                    })))));
        }
        else {
            return (React.createElement("div", null));
        }
    }
}
exports.MediaSwiper = MediaSwiper;
const mapStateToProps = (state, ownProp) => {
    return {
        restapi_url: state.ivms.restapi_url,
        query_uri: state.query_uri,
        playlist_items: state.ivms.playlist_items,
        media_items: state.ivms.media_items,
        playlistID: ownProp.playlistID,
        bigItems: ownProp.bigItems,
        showTitle: ownProp.showTitle,
        currentFilmId: ownProp.currentFilmId,
        preventReload: ownProp.preventReload,
    };
};
const mapDispatchToProps = (dispatch) => ({
    getPlaylistFromId: (id, restapi_url) => dispatch(ivms.getPlaylistFromId(id, restapi_url)),
    getMediaFromId: (id, restapi_url) => dispatch(ivms.getMediaFromId(id, restapi_url))
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(MediaSwiper);
