"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const redux_1 = require("redux");
const redux_thunk_1 = require("redux-thunk");
const ivms_redux_reducer_1 = require("./ivms/ivms-redux-reducer");
const isNode = require("detect-node");
exports.initialStoreState = {
    ivms: ivms_redux_reducer_1.initialIvmsState(),
    query_uri: "",
};
const serverSideReducer = function (state, action) {
    if (action.type === "setIvmsState") {
        state.ivms = action.ivms;
        return state;
    }
    else {
        return state;
    }
};
const queryUriReducer = function (state, action) {
    if (action.type === "SAVE_QUERY_URI") {
        state.query_uri = action.query_uri;
        return state;
    }
    else {
        return state;
    }
};
exports.saveQueryUriAction = (query_uri) => {
    return {
        type: "SAVE_QUERY_URI",
        query_uri: query_uri,
    };
};
exports.saveQueryUri = (query_uri) => {
    return (dispatch) => __awaiter(this, void 0, void 0, function* () {
        if (typeof query_uri === 'string' && query_uri.length > 0) {
            dispatch(exports.saveQueryUriAction(query_uri));
        }
        else {
            dispatch(exports.saveQueryUriAction(""));
        }
    });
};
const rootReducer = (state, action) => {
    state = ivms_redux_reducer_1.IvmsReducer(state, action);
    state = queryUriReducer(state, action);
    return state;
};
exports.serverSideStore = redux_1.createStore(serverSideReducer, exports.initialStoreState);
const preloadedState = isNode ? null : window.__PRELOADED_STATE__;
exports.AppStore = redux_1.createStore(rootReducer, preloadedState || exports.initialStoreState, redux_1.compose(redux_1.applyMiddleware(redux_thunk_1.default), (!isNode && window.devToolsExtension) ? window.devToolsExtension() : f => f));
if (!isNode)
    delete window.__PRELOADED_STATE__;
