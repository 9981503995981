"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeIvmsConfig = (ivmsConfigResponse) => {
    return {
        type: "IVMS_INITIALIZE_CONFIG",
        ivmsConfig: {
            restapi: ivmsConfigResponse.ivms.restapi,
            restapi_live: ivmsConfigResponse.ivms.live,
            geoloc_url: ivmsConfigResponse.geoloc.geoip,
            alternative_uuids: ivmsConfigResponse.geoloc.alternate,
            version_name: ivmsConfigResponse.version_name,
        }
    };
};
exports.setupIvmsRestApiUrl = (restapi_url) => {
    return {
        type: "IVMS_SETUP_API_URL",
        restapi_url: restapi_url,
    };
};
exports.getMediaSpecById = (mediaSpec) => {
    return {
        type: "IVMS_MEDIA_FETCH_SPEC",
        media_item: mediaSpec,
    };
};
exports.getPlaylistSpecById = (playlist_item) => {
    return {
        type: "IVMS_PLAYLIST_FETCH_SPEC",
        playlist_item: playlist_item,
    };
};
exports.getPlaylistSetSpecById = (playlistset_item) => {
    return {
        type: "IVMS_PLAYLISTSET_FETCH_SPEC",
        playlistset_item: playlistset_item,
    };
};
exports.getAllPlaylistsIdsAction = (all_playlists_ids) => {
    return {
        type: "IVMS_PLAYLISTS_FETCH_ALL_IDS",
        all_playlists_ids: all_playlists_ids,
    };
};
exports.getAllPlaylistSetIdsAction = (all_playlistset_ids) => {
    return {
        type: "IVMS_PLAYLISTSETS_FETCH_ALL_IDS",
        all_playlistset_ids: all_playlistset_ids,
    };
};
exports.getAllMediaIdsAction = (all_media_ids) => {
    return {
        type: "IVMS_MEDIA_FETCH_ALL_IDS",
        all_media_ids: all_media_ids,
    };
};
exports.getContainerDataById = (container) => {
    return {
        type: "IVMS_CONTAINER_DATA_FETCH",
        container: container,
    };
};
