"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
const ivms = require("../ivms/ivms");
const lodash_1 = require("lodash");
const helper_functions_1 = require("../utils/helper-functions");
const isNode = require("detect-node");
;
;
;
class ThemaKomplex extends React.Component {
    constructor() {
        super(...arguments);
        this.titles = [];
        this.state = {
            playlistset_items_ensured: false,
            playlist_items_ensured: false,
        };
    }
    ensurePlaylistItems() {
        const { playlist_items, playlistset_items, getPlaylistFromId, slug, restapi_url } = this.props;
        const playlistset_item = lodash_1.find(playlistset_items, p => p.slug === slug);
        if (playlistset_item) {
            let containsPlaylist = playlistset_item.contains_playlists;
            containsPlaylist.forEach(item => {
                let id = item.pk;
                let res = lodash_1.find(playlist_items, mi => mi.id === id);
                if (!res) {
                    getPlaylistFromId(id, restapi_url);
                }
            });
            this.setState(lodash_1.merge(this.state, { playlist_items_ensured: true }));
        }
    }
    ;
    ensurePlaylistSet(restapi_url) {
        const { playlistset_items, slug, getPlaylistSetFromId } = this.props;
        if (restapi_url) {
            const item = lodash_1.find(playlistset_items, p => p.slug === slug);
            if (lodash_1.isEmpty(item) || typeof item === 'undefined') {
                ivms.getSlug(slug, restapi_url)
                    .then(pl => getPlaylistSetFromId(pl.object_id, restapi_url));
                this.setState(lodash_1.merge(this.state, { playlistset_items_ensured: true }));
            }
        }
    }
    ;
    componentWillReceiveProps({ restapi_url }) {
        const { playlistset_items_ensured } = this.state;
        if (this.props.restapi_url != restapi_url && !playlistset_items_ensured) {
            this.ensurePlaylistSet(restapi_url);
        }
        ;
    }
    ;
    componentDidUpdate() {
        const { restapi_url } = this.props;
        const { playlistset_items_ensured, playlist_items_ensured } = this.state;
        if (restapi_url && playlistset_items_ensured && !playlist_items_ensured) {
            this.ensurePlaylistItems();
        }
    }
    componentDidMount() {
        if (this.props.slug !== 'undefined') {
            this.ensurePlaylistSet(this.props.restapi_url);
            this.ensurePlaylistItems();
        }
    }
    ;
    render() {
        if (isNode)
            this.componentDidMount();
        const { slug, playlistset_items, playlist_items } = this.props;
        const item = lodash_1.find(playlistset_items, p => p.slug === slug);
        if (lodash_1.isEmpty(item) || lodash_1.isEmpty(item.playlists) || slug === 'undefined') {
            return (React.createElement("div", { style: { height: "1200px" } }));
        }
        else {
            var hasColor = (item.tags[0] && item.tags[0].indexOf("color:") != -1);
            var bgcolor = (hasColor) ? item.tags[0].replace("color:", "#") : "#444444";
            var bgstyle = (hasColor) ? { backgroundColor: bgcolor } : {
                backgroundImage: 'url(' + helper_functions_1.getImageUrlBySlug(item, "2018-standard") + ')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center top'
            };
            var imagestyle = (hasColor) ? { paddingTop: "28%" } : { paddingTop: "44.23%" };
            return (React.createElement("div", { className: "thema-container" },
                React.createElement("div", { className: "image", style: imagestyle },
                    React.createElement("div", { className: "komplex-poster", style: bgstyle }, hasColor && React.createElement("div", { className: "text-container" },
                        React.createElement("h3", null, "OVERVIEW"),
                        React.createElement("h1", null, item.title),
                        React.createElement("p", null, item.subtitle)))),
                React.createElement("div", { className: "komplex-item-container" }, item.playlists.map((id, index) => {
                    let playlist_item = lodash_1.find(playlist_items, m => m.id === id);
                    return (React.createElement("div", { key: index, className: "komplex-item-color" },
                        React.createElement("div", { style: { backgroundColor: `${bgcolor}` } }, playlist_item && React.createElement("div", { className: "title" }, playlist_item.title)),
                        React.createElement("div", { className: "image-container" },
                            React.createElement("div", { className: "komplex-image" }, playlist_item && React.createElement("img", { src: helper_functions_1.getImageUrlBySlug(playlist_item, "2018-standard") }))),
                        playlist_item && React.createElement("a", { href: '/themen/' + playlist_item.slug + this.props.query_uri })));
                }))));
        }
        ;
    }
    ;
}
;
const mapStateToProps = (state, ownProp) => {
    return {
        restapi_url: state.ivms.restapi_url,
        query_uri: state.query_uri,
        playlistset_items: state.ivms.playlistset_items,
        playlist_items: state.ivms.playlist_items,
        slug: ownProp.slug,
    };
};
const mapDispatchToProps = (dispatch) => ({
    getPlaylistSetFromId: (id, restapi_url) => dispatch(ivms.getPlaylistSetFromId(id, restapi_url)),
    getPlaylistFromId: (id, restapi_url) => dispatch(ivms.getPlaylistFromId(id, restapi_url)),
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(ThemaKomplex);
