"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_redux_1 = require("react-redux");
const Carousel_1 = require("../containers/Carousel");
const Grid_1 = require("../containers/Grid");
const MediaSwiper_1 = require("../components/MediaSwiper");
const SectionTitle_1 = require("../components/SectionTitle");
const ivms = require("../ivms/ivms");
const lodash_1 = require("lodash");
const memoizeOne = require("memoize-one/dist/memoize-one.cjs");
const isNode = require("detect-node");
const PlaylistSet_1 = require("../components/PlaylistSet");
const SinglePlaylistSet_1 = require("../containers/SinglePlaylistSet");
const BigMediaSwiperWithCover_1 = require("../containers/BigMediaSwiperWithCover");
;
;
;
const reduceSlug = memoizeOne((items) => {
    return lodash_1.reduce(items, (acc, item) => {
        if (item.module === 'container') {
            switch (item.template_relation.template__slug) {
                case "carousel":
                    acc.push(React.createElement(Carousel_1.default, { key: "car" + item.id, id: item.id }));
                    break;
                case "grid":
                    acc.push(React.createElement(Grid_1.default, { key: "grid" + item.id, id: item.id }));
                    break;
                case "single-playlistset":
                    acc.push(React.createElement(SinglePlaylistSet_1.default, { key: "spls" + item.id, id: item.id }));
                    break;
                case "big-media-swiper-cover":
                    acc.push(React.createElement(BigMediaSwiperWithCover_1.default, { key: "bms" + item.id, id: item.id }));
                    break;
            }
        }
        else if (item.module === 'playlist') {
            acc.push([
                React.createElement(SectionTitle_1.default, { title: item.unicode, key: item.id + 'title' }),
                React.createElement(MediaSwiper_1.default, { playlistID: item.id, key: item.id, bigItems: true })
            ]);
        }
        else if (item.module === 'playlistset') {
            acc.push(React.createElement(PlaylistSet_1.default, { key: item.id, playlistsetID: item.id }));
        }
        return acc;
    }, []);
});
class Home extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {};
    }
    shouldComponentUpdate(nextProps, nextState) {
        if ((!this.props.items) ||
            (nextProps.items.length != this.props.items.length))
            return true;
        return false;
    }
    componentDidMount() {
        const { getContainerData, restapi_url, items } = this.props;
        if (restapi_url && lodash_1.isEmpty(items))
            getContainerData(9, restapi_url);
    }
    render() {
        const { restapi_url, items } = this.props;
        if (isNode)
            this.componentDidMount();
        return (React.createElement("div", { id: "app" }, items && reduceSlug(this.props.items)));
    }
}
exports.Home = Home;
const mapStateToProps = (state, ownProp) => {
    return lodash_1.merge({
        restapi_url: state.ivms.restapi_url,
        playlist_items: state.ivms.playlist_items,
    }, lodash_1.find(state.ivms.containers, (c) => { return c.id === 9; }));
};
const mapDispatchToProps = (dispatch) => ({
    getContainerData: (containerId, restapi_url) => dispatch(ivms.getContainerData(containerId, restapi_url)),
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Home);
