"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_slick_1 = require("react-slick");
const react_redux_1 = require("react-redux");
const ivms = require("../ivms/ivms");
const lodash_1 = require("lodash");
const TextTruncate = require("react-text-truncate");
const KeyboardArrowLeft_1 = require("@material-ui/icons/KeyboardArrowLeft");
const KeyboardArrowRight_1 = require("@material-ui/icons/KeyboardArrowRight");
const helper_functions_1 = require("../utils/helper-functions");
const isNode = require("detect-node");
;
;
class Carousel extends React.Component {
    componentDidMount() {
        const { getContainerData, restapi_url, items, id } = this.props;
        if (lodash_1.isEmpty(items)) {
            getContainerData(id, restapi_url);
        }
        else {
            this.ensurePlaylistsFromSlug();
        }
        if (this.refs.slider && !isNode) {
            var indexZeroHack = setInterval(() => this.refs.slider.slickGoTo(0, true), 20);
            setTimeout(() => clearInterval(indexZeroHack), 1000);
        }
    }
    ensurePlaylistsFromSlug() {
        const { items, playlist_items, getPlaylistFromId, restapi_url } = this.props;
        const slugPlaylistIds = helper_functions_1.getPlaylistIds(items);
        const loadedPlaylistIds = helper_functions_1.getPlaylistIds(playlist_items);
        slugPlaylistIds.forEach(id => {
            if (!lodash_1.includes(loadedPlaylistIds, id)) {
                getPlaylistFromId(id, restapi_url);
            }
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((typeof prevProps.items == 'undefined' || prevProps.items.length < 1) &&
            (typeof this.props.items != 'undefined' && this.props.items.length > 0))
            this.ensurePlaylistsFromSlug();
    }
    UNSAFE_componentWillMount() {
        if (isNode) {
            this.componentDidMount();
            this.ensurePlaylistsFromSlug();
        }
    }
    render() {
        const { playlist_items, items } = this.props;
        const playlistIds = helper_functions_1.getPlaylistIds(this.props.items).sort().reverse();
        const filtered_playlist_items = helper_functions_1.filterPlaylistByIds(playlistIds, playlist_items);
        const settings = {
            autoplay: false,
            arrows: false,
            dots: false,
            infinite: true,
            initialSlide: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
            transform: true,
        };
        return (React.createElement("div", { className: "carousel-parent" },
            React.createElement(KeyboardArrowLeft_1.default, { className: "carousel-left-arrow", style: { fill: "#ffffff" }, onClick: () => this.refs.slider.slickPrev() }),
            React.createElement(KeyboardArrowRight_1.default, { className: "carousel-right-arrow", style: { fill: "#ffffff" }, onClick: () => this.refs.slider.slickNext() }),
            React.createElement(react_slick_1.default, Object.assign({ ref: "slider" }, settings), filtered_playlist_items.map((item, index) => {
                return (React.createElement("div", { key: index },
                    React.createElement("div", { className: "carousel-container", style: { backgroundImage: `url(${helper_functions_1.getImageUrlBySlug(item, "2018-standard")})` } },
                        React.createElement("div", { className: "tint" }),
                        React.createElement("div", { className: "carousel-text-container" },
                            React.createElement("h3", null, "THEMA"),
                            React.createElement("h1", null, item.title),
                            lodash_1.isEmpty(item.teaser) ?
                                React.createElement(TextTruncate, { line: 4, text: item.description }) :
                                React.createElement("p", null, item.teaser)),
                        React.createElement("a", { href: '/themen/' + item.slug + this.props.query_uri }))));
            }))));
    }
    ;
}
;
const mapStateToProps = (state, ownProp) => {
    return lodash_1.merge({
        id: ownProp.id,
        restapi_url: state.ivms.restapi_url,
        query_uri: state.query_uri,
        playlist_items: state.ivms.playlist_items,
    }, lodash_1.find(state.ivms.containers, (c) => { return c.id === ownProp.id; }));
};
const mapDispatchToProps = (dispatch) => ({
    getPlaylistFromId: (id, restapi_url) => dispatch(ivms.getPlaylistFromId(id, restapi_url)),
    getContainerData: (containerId, restapi_url) => dispatch(ivms.getContainerData(containerId, restapi_url)),
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Carousel);
