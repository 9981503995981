"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const Store_1 = require("../Store");
const ivms_redux_actions_1 = require("./ivms-redux-actions");
const lodash_1 = require("lodash");
const isNode = require("detect-node");
require('es6-promise').polyfill();
require('fetch-everywhere');
const PROJECT_CONFIG_URL = 'https://svm-prod-dctptv-static.s3.amazonaws.com/relaunch/config/projectConfig01.json';
const PROJECT_VERSION_URL = 'https://s3.amazonaws.com/dctp-ivms2-restapi/version.json';
;
exports.getConfig = () => {
    return (dispatch) => __awaiter(this, void 0, void 0, function* () {
        if (isNode) {
            let serverState = Store_1.serverSideStore.getState();
            let cached_config = serverState.ivms.config;
            if (!lodash_1.isEmpty(cached_config))
                return dispatch({ type: "IVMS_INITIALIZE_CONFIG", ivmsConfig: cached_config });
        }
        try {
            const configUrl = PROJECT_CONFIG_URL;
            const versionUrl = PROJECT_VERSION_URL;
            const response = yield fetch(configUrl);
            const responseBody = yield response.json();
            const versionResponse = yield fetch(versionUrl);
            const versionResponseBody = yield versionResponse.json();
            const mergedConfigAndVersion = lodash_1.merge({}, responseBody, versionResponseBody);
            dispatch(ivms_redux_actions_1.initializeIvmsConfig(mergedConfigAndVersion));
        }
        catch (error) {
            console.error(error);
        }
    });
};
