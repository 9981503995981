"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const ivms = require("../ivms/ivms");
const react_redux_1 = require("react-redux");
const isNode = require("detect-node");
const helper_functions_1 = require("../utils/helper-functions");
;
;
class SinglePlaylistSet extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { restapi_url, id, items, getPlaylistSetFromId, playlistset_items } = this.props;
        let first_playlistset_id = items && items[0].id;
        let playlistset_item = lodash_1.find(playlistset_items, pi => {
            return pi.id === first_playlistset_id;
        });
        if (!playlistset_item && (!prevProps.items || (prevProps.items.length < 1))) {
            getPlaylistSetFromId(first_playlistset_id, restapi_url);
        }
    }
    componentDidMount() {
        const { playlistset_items, restapi_url, id, getPlaylistSetFromId, items, getContainerData } = this.props;
        if (restapi_url && lodash_1.isEmpty(items)) {
            getContainerData(id, restapi_url);
        }
        else {
            let first_playlistset_id = items[0].id;
            let playlistset_item = lodash_1.find(playlistset_items, pi => {
                return pi.id === first_playlistset_id;
            });
            if (!playlistset_item)
                getPlaylistSetFromId(first_playlistset_id, restapi_url);
        }
    }
    render() {
        if (isNode) {
            this.componentDidMount();
        }
        const { playlistset_items, id, items } = this.props;
        let first_playlistset_id = items && items[0] && items[0].id;
        let playlistset_item = lodash_1.find(playlistset_items, pi => {
            return pi.id === first_playlistset_id;
        });
        if (lodash_1.isEmpty(items) || lodash_1.isEmpty(playlistset_item)) {
            return React.createElement("div", null);
        }
        return (React.createElement("div", null,
            React.createElement("a", { href: '/themenkomplex/' + playlistset_item.slug + this.props.query_uri },
                React.createElement("div", { className: "cover", style: { backgroundImage: `url(${helper_functions_1.getImageUrlBySlug(playlistset_item, "2018-flat")})` } }))));
    }
}
exports.SinglePlaylistSet = SinglePlaylistSet;
const mapStateToProps = (state, ownProp) => {
    return lodash_1.merge({
        id: ownProp.id,
        restapi_url: state.ivms.restapi_url,
        query_uri: state.query_uri,
        playlistset_items: state.ivms.playlistset_items,
    }, lodash_1.find(state.ivms.containers, (c) => { return c.id === ownProp.id; }));
};
const mapDispatchToProps = (dispatch) => ({
    getPlaylistSetFromId: (id, restapi_url) => dispatch(ivms.getPlaylistSetFromId(id, restapi_url)),
    getContainerData: (containerId, restapi_url) => dispatch(ivms.getContainerData(containerId, restapi_url))
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(SinglePlaylistSet);
