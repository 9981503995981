"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const VideojsPlayer_1 = require("../components/VideojsPlayer");
const react_redux_1 = require("react-redux");
const react_visibility_sensor_1 = require("@tgrx/react-visibility-sensor");
const react_router_1 = require("react-router");
const ivms = require("../ivms/ivms");
const helper_functions_1 = require("../utils/helper-functions");
const SectionTitle_1 = require("../components/SectionTitle");
const MediaSwiper_1 = require("../components/MediaSwiper");
const lodash_1 = require("lodash");
const isNode = require("detect-node");
const react_device_detect_1 = require("react-device-detect");
const isPrerendered = isNode ? null : window.__ivms_ssr__;
;
;
;
class Filme extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            notfound_view: false,
            rendered_media_items: [],
            inview_media_ids: [],
            page_number: 0,
            isLoading: false,
            hasMore: true,
            playlistID_for_film: null,
            playlist_with_film_ensured: false,
            embed_started: false,
        };
    }
    maybeAppendMediaItems() {
        const { rendered_media_items, inview_media_ids, page_number } = this.state;
        const { media_items } = this.props;
        if (rendered_media_items.length != inview_media_ids.length) {
            let missingIds = lodash_1.dropWhile(inview_media_ids, (id => {
                return !!lodash_1.find(media_items, m => m.id === id);
            }));
            if (lodash_1.isEmpty(missingIds)) {
                var new_media_items = [];
                var bgcolor = "#333333";
                inview_media_ids.map((id, index) => {
                    if (index > rendered_media_items.length) {
                        var item = lodash_1.find(media_items, (p) => p.id === id);
                        new_media_items.push(React.createElement("div", { key: index, className: "thema-item" },
                            React.createElement("a", { href: '/filme/' + (item && item.slug) + this.props.query_uri, key: index + '-inner', className: "thema-item-inner" },
                                React.createElement("div", { className: "thema-item-padding" },
                                    React.createElement("div", { className: "thema-item-image", style: { backgroundImage: `url(${(item && helper_functions_1.getImageUrlBySlug(item, "medium-preview-16-9"))})` }, key: index + '-image' },
                                        React.createElement("div", { className: (react_device_detect_1.isMobile) ? "hover-play touch" : "hover-play" },
                                            React.createElement("img", { src: "/images/icon-play.png" }))),
                                    React.createElement("h3", { className: 'thema-item-title', key: index + '-link' }, item && item.title),
                                    React.createElement("p", { key: index + '-desc', className: "thema-item-teaser" }, item && item.teaser)))));
                    }
                    ;
                });
                this.setState(lodash_1.merge(this.state, {
                    rendered_media_items: lodash_1.concat(this.state.rendered_media_items, new_media_items),
                    isLoading: false,
                }));
            }
            ;
        }
        ;
    }
    ;
    getNextMediaItems(isVisible) {
        if (isVisible && !lodash_1.isEmpty(this.props.all_media_ids) && this.state.isLoading == false) {
            let newPageNumber = isNode ? 0 : this.state.page_number + 1;
            const { media_items, restapi_url, all_media_ids, getMediaFromId } = this.props;
            let slicedMediaIds = lodash_1.slice(all_media_ids, newPageNumber * 24, (newPageNumber * 24) + 24);
            if (slicedMediaIds.length < 24) {
                setTimeout(() => this.setState(lodash_1.merge(this.state, { hasMore: false })), 1000);
            }
            var shouldLoad = false;
            slicedMediaIds.forEach(id => {
                let item = lodash_1.find(media_items, m => m.id === id);
                if (lodash_1.isEmpty(item)) {
                    getMediaFromId(id, restapi_url);
                    if (!shouldLoad && !isNode) {
                        shouldLoad = true;
                    }
                    ;
                }
                ;
            });
            this.setState(lodash_1.merge(this.state, {
                inview_media_ids: lodash_1.concat(this.state.inview_media_ids, slicedMediaIds),
                page_number: newPageNumber,
                isLoading: shouldLoad,
            }));
            if (!shouldLoad) {
                this.maybeAppendMediaItems();
            }
        }
        ;
    }
    ;
    ensurePlaylistWithFilm() {
        const { restapi_url, match, media_items, playlist_items, getPlaylistFromId } = this.props;
        const slugName = match.params.id;
        const media_item = lodash_1.find(media_items, m => m.slug === slugName);
        if (media_item &&
            media_item.contained_in_playlists &&
            media_item.contained_in_playlists[0]) {
            const playlist_slug = media_item.contained_in_playlists[0];
            const playlist_item = lodash_1.find(playlist_items, p => p.id === playlist_slug.id);
            if (!playlist_item) {
                if (this.state.playlistID_for_film != null) {
                    this.setState(lodash_1.merge(this.state, { playlistID_for_film: null }));
                }
            }
            if (playlist_slug.id) {
                this.setState(lodash_1.merge(this.state, {
                    playlistID_for_film: playlist_slug.id,
                    playlist_with_film_ensured: true,
                }));
                getPlaylistFromId(playlist_slug.id, restapi_url);
            }
        }
    }
    UNSAFE_componentWillReceiveProps({ media_items, all_media_ids, match }) {
        let overviewView = typeof match.params.id === 'undefined' ? true : false;
        if (overviewView &&
            (all_media_ids.length != this.props.all_media_ids.length) &&
            (this.state.rendered_media_items.length === 0)) {
            if (isPrerendered) {
                this.setState(lodash_1.merge(this.state, {
                    inview_media_ids: lodash_1.slice(all_media_ids, 0, 24),
                }));
            }
            else {
                setTimeout(() => this.getNextMediaItems(true), 0);
            }
        }
        if (this.props.match.params.id && match.params.id != this.props.match.params.id) {
            this.ensurePlaylistWithFilm();
        }
        setTimeout(() => this.maybeAppendMediaItems(), 0);
    }
    ;
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { restapi_url, all_media_ids, match, media_items, playlist_items, getPlaylistFromId } = this.props;
        let overviewView = typeof match.params.id === 'undefined' ? true : false;
        if (overviewView && restapi_url && lodash_1.isEmpty(all_media_ids)) {
            this.props.getAllMediaIds(restapi_url);
        }
        if (overviewView &&
            (all_media_ids.length != this.props.all_media_ids.length) &&
            (this.state.rendered_media_items.length === 0)) {
            this.getNextMediaItems(true);
        }
        if (overviewView && (this.state.rendered_media_items.length === 0)) {
            this.maybeAppendMediaItems();
        }
        if (!overviewView && !this.state.playlist_with_film_ensured) {
            this.ensurePlaylistWithFilm();
        }
    }
    componentDidMount() {
        const { restapi_url, all_media_ids, match } = this.props;
        let overviewView = typeof match.params.id === 'undefined' ? true : false;
        if (overviewView && restapi_url && lodash_1.isEmpty(all_media_ids)) {
            this.props.getAllMediaIds(restapi_url);
        }
        if (overviewView && !isPrerendered && !lodash_1.isEmpty(all_media_ids)) {
            this.getNextMediaItems(true);
        }
        if (!overviewView && !this.state.playlist_with_film_ensured) {
            this.ensurePlaylistWithFilm();
        }
        if (!overviewView && !isNode) {
            scrollTo(0, 0);
        }
    }
    render() {
        if (isNode)
            this.componentDidMount();
        const { playlist_items, media_items, match } = this.props;
        let overviewView = typeof match.params.id === 'undefined' ? true : false;
        let embeddedView = !overviewView && match.path.endsWith("/embed");
        if (overviewView) {
            return (React.createElement("div", { className: "thema-container", style: { paddingTop: "92px" } },
                React.createElement(SectionTitle_1.default, { title: "Filme" }),
                React.createElement("div", { className: "thema-media-grid", style: { marginTop: "12px" } }, this.state.rendered_media_items),
                this.state.hasMore &&
                    React.createElement(react_visibility_sensor_1.default, { className: "themen-loader-container", onChange: this.getNextMediaItems.bind(this) },
                        React.createElement("div", { className: "spinner" },
                            React.createElement("div", { className: "rect1" }),
                            React.createElement("div", { className: "rect2" }),
                            React.createElement("div", { className: "rect3" }),
                            React.createElement("div", { className: "rect4" }),
                            React.createElement("div", { className: "rect5" })))));
        }
        else {
            const { playlistID_for_film } = this.state;
            const slugName = match.params.id;
            const media_item = lodash_1.find(media_items, m => m.slug === slugName);
            var playlist_item;
            if (playlistID_for_film) {
                playlist_item = lodash_1.find(playlist_items, p => p.id === playlistID_for_film);
            }
            var playlistTitle, playlistCoverImageURL, playlistSubtitle, playlistDescription;
            if (playlist_item) {
                playlistTitle = playlist_item.title;
                playlistCoverImageURL = playlist_item.images[0].url;
                playlistSubtitle = playlist_item.subtitle;
                playlistDescription = playlist_item.description;
            }
            else {
                playlistTitle = media_item ? media_item.title : 'DCTP - Media';
                playlistCoverImageURL = media_item ? media_item.images[Math.max(0, media_item.images.length - 1)].url : null;
                playlistSubtitle = media_item ? media_item.subtitle : '';
                playlistDescription = media_item ? media_item.description : '';
            }
            if (embeddedView) {
                return slugName && (React.createElement("div", { className: "video-js-embedded-container", onClick: (event) => this.setState(lodash_1.merge(this.state, { embed_started: true })) },
                    React.createElement("style", null, `header,footer {display: none;} 
                                 body{background-color: #000000!important;
                                 padding: 0!important;}
                                 html {margin: 0!important; overflow:hidden;}
                                 main {height: 100%!important;}
                                 main > div {width: 100%; height: 100%;}`),
                    React.createElement(VideojsPlayer_1.default, { key: slugName, slug: slugName, embedded: true }),
                    !this.state.embed_started &&
                        React.createElement("div", { className: "embed-videoinfo-container" },
                            React.createElement("h1", { className: "embed-title" }, media_item && media_item.title || playlistTitle),
                            React.createElement("h2", { className: "embed-subtitle" }, media_item && media_item.subtitle || playlistSubtitle))));
            }
            return (React.createElement("div", { className: "film-container" },
                slugName && React.createElement(VideojsPlayer_1.default, { key: slugName, slug: slugName }),
                React.createElement("h3", { className: "filme-title" }, media_item && media_item.title),
                React.createElement("div", { className: "filme-subtitle" },
                    React.createElement("i", null, media_item && media_item.subtitle)),
                React.createElement("div", { className: "filme-description" },
                    React.createElement("p", null, media_item && media_item.description)),
                playlistID_for_film &&
                    React.createElement(MediaSwiper_1.default, { playlistID: playlistID_for_film, bigItems: false, showTitle: true, currentFilmId: media_item.id, preventReload: true })));
        }
        ;
    }
    ;
}
;
const mapStateToProps = (state, ownProp) => {
    return {
        restapi_url: state.ivms.restapi_url,
        query_uri: state.query_uri,
        media_items: state.ivms.media_items,
        all_media_ids: state.ivms.all_media_ids,
        playlist_items: state.ivms.playlist_items,
    };
};
const mapDispatchToProps = (dispatch) => ({
    getMediaFromId: (id, restapi_url) => dispatch(ivms.getMediaFromId(id, restapi_url)),
    getAllMediaIds: (restapi_url) => dispatch(ivms.getAllMediaIds(restapi_url)),
    getPlaylistFromId: (id, restapi_url) => dispatch(ivms.getPlaylistFromId(id, restapi_url)),
});
exports.default = react_router_1.withRouter(react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Filme));
