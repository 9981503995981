"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const lodash_1 = require("lodash");
const ivms = require("../ivms/ivms");
const react_redux_1 = require("react-redux");
const helper_functions_1 = require("../utils/helper-functions");
;
;
class PlaylistSet extends React.Component {
    render() {
        const { playlistset_items, playlistsetID } = this.props;
        const playlistIds = helper_functions_1.getPlaylistSetIds(this.props.items);
        const filtered_playlistset_items = helper_functions_1.filterPlaylistSetsByIds([playlistsetID], playlistset_items);
        return (React.createElement("div", null, filtered_playlistset_items.map((item, index_inner) => {
            return (React.createElement("a", { href: '/themenkomplex/' + item.slug, key: index_inner },
                React.createElement("div", { className: "cover", style: { backgroundImage: `url(${helper_functions_1.getImageUrlBySlug(item, "2018-flat")})` } })));
        })));
    }
    componentDidMount() {
        const { playlistset_items, restapi_url, playlistsetID, getPlaylistSetFromId } = this.props;
        let playlistset_item = lodash_1.find(playlistset_items, pi => {
            return pi.id === playlistsetID;
        });
        if (!playlistset_item) {
            getPlaylistSetFromId(playlistsetID, restapi_url);
        }
    }
}
exports.PlaylistSet = PlaylistSet;
const mapStateToProps = (state, ownProp) => {
    return lodash_1.merge({
        restapi_url: state.ivms.restapi_url,
        playlistsetID: ownProp.playlistsetID,
        playlistset_items: state.ivms.playlistset_items,
    }, lodash_1.find(state.ivms.containers, (c) => { return c.slug === 'collections'; }));
};
const mapDispatchToProps = (dispatch) => ({
    getPlaylistSetFromId: (id, restapi_url) => dispatch(ivms.getPlaylistSetFromId(id, restapi_url))
});
exports.default = react_redux_1.connect(mapStateToProps, mapDispatchToProps)(PlaylistSet);
