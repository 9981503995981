"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const moment_1 = require("moment");
exports.isEmptyObject = (obj) => !Object.keys(obj).length;
exports.getPlaylistIds = (playlistArray) => {
    return lodash_1.reduce(playlistArray, (acc, p) => {
        acc.push(p.id);
        return acc;
    }, []);
};
exports.getPlaylistSetIds = (playlistsetArray) => {
    return lodash_1.reduce(playlistsetArray, (acc, p) => {
        acc.push(p.id);
        return acc;
    }, []);
};
exports.filterPlaylistByIds = (idArray, playlist_items) => {
    return lodash_1.reduce(idArray, (acc, id) => {
        let playlist_item = lodash_1.find(playlist_items, p => p.id === id);
        if (playlist_item) {
            acc.push(playlist_item);
            return acc;
        }
        else {
            return acc;
        }
    }, []);
};
exports.filterPlaylistSetsByIds = (idArray, playlistset_items) => {
    return lodash_1.reduce(idArray, (acc, id) => {
        let playlistset_item = lodash_1.find(playlistset_items, p => p.id === id);
        if (playlistset_item) {
            acc.push(playlistset_item);
            return acc;
        }
        else {
            return acc;
        }
    }, []);
};
exports.ms2mins = (ms) => {
    const dur = moment_1.duration(ms, 'ms');
    return '' + Math.ceil(moment_1.duration(ms, 'ms').asMinutes());
};
exports.getImageUrlBySlug = (item, slug) => {
    let img = item.images.filter((a) => {
        return a.spec_slug == slug;
    })[0];
    return (img) ? img.url : (item.images.length) ? item.images[0].url : "";
};
exports.getSmallestImage = (images) => {
    var smallest_val;
    var url = "";
    images.forEach((img) => {
        if (!smallest_val) {
            smallest_val = img.width;
            url = img.url;
        }
        else if (img.width < smallest_val) {
            smallest_val = img.width;
            url = img.url;
        }
    });
    return url;
};
exports.randomString = (length) => {
    length = length || 10;
    let s = "";
    let c = function () {
        let a = (Math.random() < 0.5 ? 65 : 97);
        return String.fromCharCode(a + Math.floor((Math.random() * 26)));
    };
    for (let i = 0; i < length; i++) {
        s = s + c();
    }
    return s;
};
