"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
const react_router_dom_2 = require("react-router-dom");
const react_redux_1 = require("react-redux");
const Home_1 = require("./pages/Home");
const Filme_1 = require("./pages/Filme");
const Themen_1 = require("./pages/Themen");
const Header_1 = require("./components/Header");
const NotFound_1 = require("./components/NotFound");
const ivmsConfig = require("./ivms/ivms-config");
const Footer_1 = require("./components/Footer");
const isNode = require("detect-node");
const Store_1 = require("./Store");
const ThemenKomplex_1 = require("./pages/ThemenKomplex");
const Impressum_1 = require("./pages/Impressum");
const queryString = require("query-string");
class Root extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.restapi_url !== nextProps.restapi_url) {
            return true;
        }
        else {
            return false;
        }
    }
    componentDidMount() {
        const { getIvmsConfig, saveQueryUri } = this.props;
        this.props.getIvmsConfig();
        if (!isNode) {
            let query_string_map = queryString.parse(location.search);
            if (typeof query_string_map.restapi_url === 'string') {
                saveQueryUri("/?" + queryString.stringify({ restapi_url: query_string_map.restapi_url }));
            }
        }
    }
    render() {
        if (isNode)
            this.props.getIvmsConfig();
        if (!this.props.restapi_url && !isNode) {
            return (React.createElement("div", null, JSON.stringify(this.props)));
        }
        else {
            return (React.createElement("div", null,
                React.createElement(Header_1.default, null),
                React.createElement(react_router_dom_1.Switch, null,
                    React.createElement(react_router_dom_1.Route, { exact: true, path: "/", component: Home_1.default }),
                    React.createElement(react_router_dom_1.Route, { path: "/filme/:id?/embed", component: Filme_1.default }),
                    React.createElement(react_router_dom_1.Route, { path: "/filme/:id?", component: Filme_1.default }),
                    React.createElement(react_router_dom_1.Route, { path: "/themen/:id?", component: Themen_1.default }),
                    React.createElement(react_router_dom_1.Route, { path: "/themenkomplex/:id?", component: ThemenKomplex_1.default }),
                    React.createElement(react_router_dom_1.Route, { path: "/impressum", component: Impressum_1.default }),
                    React.createElement(react_router_dom_1.Route, { component: NotFound_1.NotFound })),
                React.createElement(Footer_1.Footer, null)));
        }
    }
}
const mapStateToProps = (state, ownProp) => {
    return {
        restapi_url: state.ivms.restapi_url,
        query_uri: state.query_uri,
    };
};
const mapDispatchToProps = (dispatch) => ({
    getIvmsConfig: () => dispatch(ivmsConfig.getConfig()),
    saveQueryUri: (query_uri) => dispatch(Store_1.saveQueryUri(query_uri)),
});
exports.default = react_router_dom_2.withRouter(react_redux_1.connect(mapStateToProps, mapDispatchToProps)(Root));
