"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const isNode = require("detect-node");
const queryString = require("query-string");
exports.initialIvmsState = () => ({
    config: {},
    restapi_url: "",
    media_items: [],
    playlist_items: [],
    playlistset_items: [],
    all_playlists_ids: [],
    all_playlistset_ids: [],
    all_media_ids: [],
    containers: [],
});
function mergeIvms(state, objectToMerge) {
    state.ivms = lodash_1.merge(state.ivms, objectToMerge);
    return Object.assign({}, state);
}
function wantsDifferentEnpoint() {
    if (!isNode &&
        location &&
        location.search &&
        queryString.parse(location.search).restapi_url &&
        queryString.parse(location.search).restapi_url.length > 0) {
        return true;
    }
    else {
        return false;
    }
}
function resolveRestapiEndpoint(ivmsConfig) {
    if (DEVELOPMENT) {
        if (wantsDifferentEnpoint()) {
            return queryString.parse(location.search).restapi_url;
        }
        else {
            return ivmsConfig.restapi_live;
        }
    }
    else {
        if (wantsDifferentEnpoint()) {
            return queryString.parse(location.search).restapi_url;
        }
        else {
            return ivmsConfig.restapi.replace('[version]', ivmsConfig.version_name) + 'restapi/';
        }
    }
}
function IvmsReducer(state, action) {
    if (typeof state == 'undefined') {
        let init_state = { ivms: exports.initialIvmsState(), query_uri: "" };
        return init_state;
    }
    switch (action.type) {
        case "IVMS_INITIALIZE_CONFIG":
            var restapiUrl = resolveRestapiEndpoint(action.ivmsConfig);
            state.ivms.restapi_url = restapiUrl;
            state.ivms.config = action.ivmsConfig;
            break;
        case "IVMS_MEDIA_FETCH_SPEC":
            state.ivms.media_items = lodash_1.concat(state.ivms.media_items || [], [action.media_item]);
            break;
        case "IVMS_PLAYLIST_FETCH_SPEC":
            state.ivms.playlist_items = lodash_1.concat(state.ivms.playlist_items || [], [action.playlist_item]);
            break;
        case "IVMS_PLAYLISTS_FETCH_ALL_IDS":
            state.ivms.all_playlists_ids = lodash_1.concat(state.ivms.all_playlists_ids || [], action.all_playlists_ids);
            break;
        case "IVMS_PLAYLISTSET_FETCH_SPEC":
            state.ivms.playlistset_items = lodash_1.concat(state.ivms.playlistset_items || [], action.playlistset_item);
            break;
        case "IVMS_PLAYLISTSETS_FETCH_ALL_IDS":
            state.ivms.all_playlistset_ids = lodash_1.concat(state.ivms.all_playlistset_ids || [], action.all_playlistset_ids);
            break;
        case "IVMS_MEDIA_FETCH_ALL_IDS":
            state.ivms.all_media_ids = action.all_media_ids;
            break;
        case "IVMS_CONTAINER_DATA_FETCH":
            state.ivms.containers = lodash_1.concat(state.ivms.containers || [], [action.container]);
            break;
        case "CLEAR_ALL":
            const restapi_url = state.ivms.restapi_url;
            state.ivms = exports.initialIvmsState();
            state.ivms.restapi_url = restapi_url;
            break;
        case "GET_CONFIG_FROM_SERVER_CACHE":
            return mergeIvms(state, {
                config: Object.assign({}, action.ivmsConfig),
                restapi_url: action.restapi_url,
            });
            break;
    }
    return Object.assign({}, state);
}
exports.IvmsReducer = IvmsReducer;
